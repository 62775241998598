import React, { useEffect } from "react";
import "twin.macro";

import { Event, CheckoutDetails } from "api/v2/event.types";
import { OrderFormValues } from "./orderForm.types";

import RadioButtonInput from "components/forms/RadioButtonInput";
import {
  FormSection,
  Legend,
  Description,
  ResponsiveFloatingSubmit
} from "../styles";
import {
  useParams,
  useHistory,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import { Button, P } from "components/typography";
import Input from "components/forms/input";
import ErrorMessage from "components/forms/ErrorMessage";
//import { currencyFormatter } from "helpers/formatters";

import { useAuth } from "components/AuthProvider";
import { useCart } from "components/CartProvider";
import { useForm, SubmitHandler } from "react-hook-form";

import D2dFormSection from "./d2dFormSection";
import PickupFormSection from "./pickupFormSection";
import Promocode from "./promocode";
import Gratuity from "./gratuity";

const OrderForm: React.FC<{
  checkoutDetails: CheckoutDetails;
  event: Event;
  setGratuity: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPromocode: React.Dispatch<React.SetStateAction<string | undefined>>;
  setdeliveryType: React.Dispatch<React.SetStateAction<string | undefined>>;
  setOrderFormValues: React.Dispatch<
    React.SetStateAction<OrderFormValues | undefined>
  >;
  orderFormValues?: OrderFormValues;
}> = ({
  checkoutDetails,
  event,
  setGratuity,
  setPromocode,
  setdeliveryType,
  setOrderFormValues,
  orderFormValues
}) => {
  const { id: eventId } = useParams<{ id: string }>();
  const history = useHistory();
  const cart = useCart();
  const auth = useAuth();
  const { url } = useRouteMatch();

  const {
    handleSubmit,
    errors,
    register,
    watch,
    getValues,
    trigger,
    clearErrors
  } = useForm<OrderFormValues>({
    mode: "onBlur",
    defaultValues: {
      deliveryType: checkoutDetails.enableD2d
        ? checkoutDetails.d2d || event.d2dExclusive
          ? "d2d"
          : undefined
        : "pickup",
      timeSlotId:
        checkoutDetails.availableTimeSlots.length === 1
          ? checkoutDetails.availableTimeSlots[0].id.toString()
          : "",
      ...(checkoutDetails.enableGratuity
        ? {
            gratuity: (
              (checkoutDetails.defaultGratuityPercentage / 100) *
              checkoutDetails.baseCost
            ).toString()
          }
        : {}),
      deliveryStreet: checkoutDetails.customerStreet,
      deliveryCity: checkoutDetails.customerCity,
      deliveryState: checkoutDetails.customerState,
      deliveryPostalCode: checkoutDetails.customerPostalCode,
      saveDefaultAddress: true,
      ...orderFormValues
    }
  });
  const watchedTimeSlot = watch("timeSlotId");
  const watchedGratuity = watch("gratuity");
  const watchedDeliveryType = watch("deliveryType");

  useEffect(() => setGratuity(watchedGratuity), [watchedGratuity]);
  useEffect(() => {
    setdeliveryType(watchedDeliveryType);
    clearErrors();
  }, [watchedDeliveryType]);

  if (!eventId || !event) return <Redirect to="/404project" />;
  if (!cart.state[eventId]) return <Redirect to={`/events/${eventId}`} />;

  const onSubmit: SubmitHandler<OrderFormValues> = (values) => {
    setOrderFormValues(values);
    history.push(`${url}/payment`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} tw="pb-16">
      {checkoutDetails.enableD2d && !event.d2dExclusive && (
        <FormSection>
          <Description>
            <Legend hasError={!!errors["deliveryType"]}>
              Select order type
            </Legend>
            {errors["deliveryType"] && (
              <P.Small tw="text-danger">You must select an order type</P.Small>
            )}
          </Description>
          <div tw="w-full md:w-3/4">
            <div tw="flex flex-col md:flex-row gap-2">
              <RadioButtonInput
                tw="bg-info  hover:(bg-info-dark text-white)"
                name={`deliveryType`}
                id={`deliveryType-d2d`}
                ref={register({ required: true })}
                type="radio"
                value={"d2d"}
                isChecked={watchedDeliveryType === "d2d"}
              >
                Direct to door delivery
              </RadioButtonInput>
              <RadioButtonInput
                name={`deliveryType`}
                id={`deliveryType-pickup`}
                ref={register({ required: true })}
                type="radio"
                value={"pickup"}
                isChecked={watchedDeliveryType === "pickup"}
              >
                Pickup at address below
              </RadioButtonInput>
            </div>
            <P.Small tw="text-muted pt-6">
              We now offer delivery direct from Chicago’s best restaurants to
              your front door for an additional fee. If you do not want direct
              to door delivery, please select “pickup at address below” to
              pickup your food at a designated pickup spot in your selected
              suburb.
            </P.Small>
          </div>
        </FormSection>
      )}
      {event.d2dExclusive ? (
        <input
          tw="hidden"
          type="hidden"
          id="deliveryType-d2d"
          name="deliveryType"
          ref={register()}
          value="d2d"
        />
      ) : null}
      {watchedDeliveryType && (
        <>
          {watchedDeliveryType === "d2d" ? (
            <D2dFormSection
              event={event}
              register={register}
              errors={errors}
              watch={watch}
              trigger={trigger}
            />
          ) : (
            <PickupFormSection
              checkoutDetails={checkoutDetails}
              event={event}
              register={register}
              errors={errors}
              watchedTimeSlot={watchedTimeSlot}
            />
          )}

          <FormSection>
            <Description>
              <Legend>Account Details</Legend>
            </Description>
            <div tw="flex flex-col gap-4">
              <P.Medium>
                <i className="fas fa-user" tw="text-muted mr-6" />
                {auth.user?.name}
              </P.Medium>
              <P.Medium>
                <i className="fas fa-envelope" tw="text-muted mr-6" />
                {auth.user?.email}
              </P.Medium>
              <P.Medium>
                <i className="fas fa-phone" tw="text-muted mr-6" />
                {auth.user?.phone}
              </P.Medium>
            </div>
          </FormSection>

          <Gratuity
            register={register}
            defaultGratuityPercentage={
              checkoutDetails.defaultGratuityPercentage
            }
            gratuityLabel={checkoutDetails.gratuityLabel}
            enableGratuity={checkoutDetails.enableGratuity}
            subtotal={checkoutDetails.baseCost}
            watchedGratuity={watchedGratuity}
            hasError={!!errors.gratuity}
          />

          <Promocode
            checkoutDetails={checkoutDetails}
            register={register}
            getValues={getValues}
            setPromocode={setPromocode}
          />

          {checkoutDetails.enableOrderNotes && (
            <FormSection tw="md:items-center">
              <Description>
                <Legend>Notes</Legend>
              </Description>
              <div tw="w-full md:w-3/4">
                <Input
                  name={`notes`}
                  id={`notes`}
                  ref={register()}
                  type="text"
                  tw="w-full"
                />
              </div>
            </FormSection>
          )}
        </>
      )}
      <ResponsiveFloatingSubmit>
        <Button.Primary tw="w-full py-3" type="submit">
          Continue to payment details
        </Button.Primary>
        <ErrorMessage hasError={Object.keys(errors).length > 0}>
          The form is incomplete. Please double check your selections.
        </ErrorMessage>
      </ResponsiveFloatingSubmit>
    </form>
  );
};

export default OrderForm;
