import * as React from "react";
import api, { extractError } from "api/api";
import { loadStripe } from "@stripe/stripe-js";
import { useCart, cart } from "components/CartProvider";
import { useAuth, LeadTracking } from "components/AuthProvider";
import { createOrder } from "api/v2/events";
import Spinner from "components/spinner";
import { parse } from "query-string";

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from "@stripe/react-stripe-js";

const fetchCheckoutSession = (eventId: any, sessionId: any) =>
  api
    .get(
      `/api/v2/events/${eventId}/checkout_session.json?session_id=${sessionId}`
    )
    .then((resp) => resp.data)
    .catch((e) => {
      throw extractError(e);
    });

const stripePromise = process.env.REACT_APP_STRIPE_KEY
  ? loadStripe(process.env.REACT_APP_STRIPE_KEY)
  : null;

const spinner = () => (
  <div tw="flex w-full justify-center p-6">
    {" "}
    <Spinner disabled={false} />{" "}
  </div>
);

const Status: React.FC<{
  eventId: any;
  sessionId: any;
  promocode: any;
  orderFormValues: any;
  onPaymentFail: any;
  onPaymentSuccess: any;
}> = ({
  eventId,
  sessionId,
  orderFormValues,
  promocode,
  onPaymentFail,
  onPaymentSuccess
}) => {
  const cart = useCart();
  const auth = useAuth();
  const [status, setStatus] = React.useState();
  const [orderId, setOrderId] = React.useState(false);
  const [transactionId, setTransactionId] = React.useState();

  React.useEffect(() => {
    if (status == "complete" && transactionId) {
      createOrder({
        id: eventId,
        clientSecret: transactionId,
        eventCart: cart.state[eventId],
        ...orderFormValues,
        leadTracking: auth.leadTracking || {},
        promocode: promocode || ""
      }).then(({ id, ...rest }) => {
        console.log("Saved Order", id, rest);
        setOrderId(id);
      });
    }
  }, [status, transactionId]);

  React.useEffect(() => {
    fetchCheckoutSession(eventId, sessionId).then(
      ({ status, payment_intent }) => {
        setStatus(status);
        setTransactionId(payment_intent);
      }
    );
  }, []);

  if (!status || (status == "complete" && !orderId)) {
    return spinner();
  }

  switch (status) {
    case "open":
      // redirect back to payment
      return onPaymentFail();
      break;
    case "complete":
      // submit the full order to our side
      console.log("successful purchase");

      return onPaymentSuccess(orderId);

      break;
    default:
      console.log("stripe status", status);
      return (
        <div tw="flex w-full justify-center p-6">
          <Spinner disabled={false} />
        </div>
      );
  }
};

export default Status;
