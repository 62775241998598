import * as React from "react";
import api, { extractError } from "api/api";
import { loadStripe } from "@stripe/stripe-js";
import Spinner from "components/spinner";

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from "@stripe/react-stripe-js";

const createCheckoutSession = (eventId: any, data: any) =>
  api
    .post(`/api/v2/events/${eventId}/checkout_session.json`, {
      data
    })
    .then((resp) => resp.data)
    .catch((e) => {
      throw extractError(e);
    });

const stripePromise = process.env.REACT_APP_STRIPE_KEY
  ? loadStripe(process.env.REACT_APP_STRIPE_KEY)
  : null;

const Session: React.FC<{
  eventId: string;
  checkoutDetailsPayload: any;
}> = ({ eventId, checkoutDetailsPayload }) => {
  const [clientSecret, setClientSecret] = React.useState();
  React.useEffect(() => {
    createCheckoutSession(eventId, checkoutDetailsPayload()).then(
      ({ clientSecret }) => {
        setClientSecret(clientSecret);
      }
    );
  }, []);

  if (!clientSecret) {
    return (
      <div tw="flex w-full justify-center p-6">
        <Spinner disabled={false} />
      </div>
    );
  }

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{ clientSecret }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default Session;
